import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { jsPDF } from "jspdf";
import { FaFilePdf, FaArrowCircleLeft, FaYoutube } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import "./SummaryPage.css";

const SummaryPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { questions, jobDescription, currentQuestionIndex } = location.state || {
    questions: [],
    jobDescription: "",
    currentQuestionIndex: 0,
  };

  // Function to handle YouTube search
  const handleSearchYouTube = (searchTerm) => {
    const youtubeSearchUrl = `https://www.youtube.com/results?search_query=${encodeURIComponent(searchTerm)}`;
    window.open(youtubeSearchUrl, "_blank");
  };

  // Function to extract YouTube search term from study material
  const extractYouTubeSearchTerm = (studyMaterial) => {
    const youtubeLine = studyMaterial
      .split("\n")
      .find((line) => line.toLowerCase().includes("youtube tutorial: search"));
    if (youtubeLine) {
      return youtubeLine.replace("YouTube Tutorial: Search", "").replace(/['"]/g, "").trim();
    }
    return null;
  };

  // Function to handle PDF download
  const handleDownloadPDF = () => {
    if (questions.length === 0) {
      console.error("No questions available to download.");
      return;
    }

    const doc = new jsPDF();

    // Add title
    doc.setFontSize(18);
    doc.text("Job Description Q&R", 10, 10);

    // Add Q&A pairs with text wrapping and auto-pagination
    let yPosition = 20;
    const maxWidth = 180;
    const lineHeight = 6;
    const pageHeight = doc.internal.pageSize.height;

    questions.forEach((qr, index) => {
      if (yPosition > pageHeight - 20) {
        doc.addPage();
        yPosition = 20;
      }

      // Add question
      const questionLines = doc.splitTextToSize(`Q${index + 1}: ${qr.question}`, maxWidth);
      doc.setFontSize(12);
      doc.text(questionLines, 10, yPosition);
      yPosition += questionLines.length * lineHeight;

      // Add answer
      const answerLines = doc.splitTextToSize(`R: ${qr.answer}`, maxWidth);
      doc.text(answerLines, 15, yPosition);
      yPosition += answerLines.length * lineHeight + 5;

      // Add recommended study material
      if (qr.recommendedStudyMaterial) {
        const studyMaterialLines = doc.splitTextToSize(`Study Material: ${qr.recommendedStudyMaterial}`, maxWidth);
        doc.text(studyMaterialLines, 15, yPosition);
        yPosition += studyMaterialLines.length * lineHeight + 10; // Add extra spacing
      }

      yPosition += 10; // Add extra spacing between Q&A pairs
    });

    doc.save("job-description-qr.pdf");
  };

  return (
    <Container className="my-5">
      {/* Back Buttons and Download PDF Button */}
      <Row className="mb-4">
        <Col className="d-flex justify-content-between align-items-center">
          <Button
            variant="light"
            onClick={() =>
              navigate("/questions", {
                state: {
                  questions,
                  jobDescription,
                  currentQuestionIndex,
                },
              })
            }
            className="back-button"
          >
            <FaArrowCircleLeft className="me-2" />
            Back to Questions
          </Button>
          <Button variant="success" onClick={handleDownloadPDF} className="download-pdf-button">
            <FaFilePdf className="me-2" />
            Download PDF
          </Button>
        </Col>
      </Row>

      {/* Summary Title */}
      <Row className="text-center mb-4">
        <Col>
          <h1>Summary</h1>
        </Col>
      </Row>

      {/* All Q&R Pairs */}
      <Row className="justify-content-center">
        <Col xs={12} md={10} lg={8}>
          <div className="summary-box">
            {questions.map((qr, index) => (
              <div key={index} className="question-container">
                <h4>Question {index + 1}</h4>
                <p>{qr.question}</p>
                <p><strong>Response:</strong> {qr.answer}</p>
                {qr.recommendedStudyMaterial && (
                  <div className="study-material">
                    <p1><strong>Recommended Study Material:</strong></p1>
                    <ul className="study-material-list">
                      {qr.recommendedStudyMaterial.split("\n").map((line, idx) => (
                        <li key={idx}>
                          {line.trim()}
                          {/* Add "Search on YouTube" button if a YouTube search term exists */}
                          {line.toLowerCase().includes("youtube tutorial: search") && (
                            <Button
                              variant="outline-danger"
                              size="sm"
                              className="ms-2"
                              onClick={() => handleSearchYouTube(extractYouTubeSearchTerm(qr.recommendedStudyMaterial))}
                            >
                              <FaYoutube className="me-1" />
                              Search on YouTube
                            </Button>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default SummaryPage;