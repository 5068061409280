import React, { useState } from "react";
import { Button, Form, Container, Row, Col, Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import "./App.css";

const JobDescriptionInput = () => {
  const [jobDescription, setJobDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleGenerateQuestions = async () => {
    setLoading(true);
    try {
      const functionUrl = process.env.REACT_APP_FUNCTION_URL;
      const response = await fetch(functionUrl, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ text: jobDescription }),
      });
      if (!response.ok) {
        const errorData = await response.json(); // Parse error response
        throw new Error(errorData.error || `HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      console.log("Backend Response:", data);
      navigate("/questions", { state: { questions: data.qr_pairs } });
    } catch (error) {
      console.error("Error generating questions:", error);
      alert(`Error: ${error.message}`); // Notify the user of the error
    } finally {
      setLoading(false);
    }
  };

  // Character limit
  const characterLimit = 5000;
  const remainingCharacters = characterLimit - jobDescription.length;

  return (
    <Container className="d-flex flex-column align-items-center justify-content-center vh-100 p-3">
      <Row className="text-center mb-4">
        <Col>
          <h1 className="display-5">Smart Prep, Confident Responses.<br />Good Interview Karma Starts Here.</h1>
          <p className="lead">Transform job descriptions into interview-ready questions with expert guidance.</p>
        </Col>
      </Row>
      <Row className="w-100">
        <Col xs={12} md={{ span: 8, offset: 2 }}>
          <Form>
            <Form.Group controlId="jobDescription">
              <div style={{ position: "relative" }}>
                <Form.Control
                  as="textarea"
                  rows={11}
                  value={jobDescription}
                  onChange={(e) => setJobDescription(e.target.value)}
                  placeholder="Paste the job description here"
                  className="mb-3"
                  maxLength={5000}
                  style={{ paddingRight: "60px", width: "100%", resize: "none" }}
                />
                {/* Character counter */}
                <div
                  style={{
                    position: "absolute",
                    bottom: "20px",
                    right: "20px",
                    color: remainingCharacters < 0 ? "red" : "#6c757d",
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                    padding: "2px 5px",
                    borderRadius: "3px",
                    fontSize: "0.9rem",
                  }}
                >
                  {remainingCharacters}
                </div>
              </div>
            </Form.Group>

            {/* Centered Button */}
            <div className="d-flex justify-content-center">
              <Button
                variant="dark"
                onClick={handleGenerateQuestions}
                disabled={loading || jobDescription.length === 0 || jobDescription.length > 5000}
                style={{ fontSize: "1.2rem", padding: "10px 20px", width: "100%", maxWidth: "300px" }}
              >
                {loading ? (
                  <>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    <span className="ms-2">Generating...</span>
                  </>
                ) : (
                  "Generate Questions"
                )}
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default JobDescriptionInput;