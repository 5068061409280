import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import JobDescriptionInput from "./JobDescriptionInput";
import QuestionsPage from "./QuestionsPage";
import SummaryPage from "./SummaryPage"; // Import the new page
import "./App.css";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<JobDescriptionInput />} />
        <Route path="/questions" element={<QuestionsPage />} />
        <Route path="/summary" element={<SummaryPage />} /> {/* Add this route */}
      </Routes>
    </Router>
  );
}

export default App;