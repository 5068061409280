import React, { useEffect, useState } from "react";
import { Button, Container, Row, Col, Spinner } from "react-bootstrap";
import { FaArrowLeft, FaArrowRight, FaEye, FaEyeSlash, FaArrowCircleLeft } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import "./QuestionsPage.css";

const QuestionsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(location.state?.currentQuestionIndex || 0);
  const [showResponse, setShowResponse] = useState(false);
  const [loading, setLoading] = useState(true);

  // Fetch questions from location state
  useEffect(() => {
    if (location.state?.questions) {
      setQuestions(location.state.questions);
      setLoading(false);
    } else {
      navigate("/");
    }
  }, [location, navigate]);

  const handleNextQuestion = () => {
    if (currentQuestionIndex + 1 < questions.length) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      setShowResponse(false);
    }
  };

  const handlePreviousQuestion = () => {
    setCurrentQuestionIndex((prevIndex) =>
      prevIndex - 1 >= 0 ? prevIndex - 1 : questions.length - 1
    );
    setShowResponse(false);
  };

  const handleSummary = () => {
    navigate("/summary", {
      state: {
        questions,
        jobDescription: location.state?.jobDescription,
        currentQuestionIndex,
      },
    });
  };

  if (loading) {
    return (
      <Container className="d-flex justify-content-center align-items-center vh-100">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  if (!questions || questions.length === 0) {
    return (
      <Container className="d-flex justify-content-center align-items-center vh-100">
        <p>No questions available. Please try again.</p>
      </Container>
    );
  }

  const currentQuestion = questions[currentQuestionIndex];
  if (!currentQuestion) {
    return (
      <Container className="d-flex justify-content-center align-items-center vh-100">
        <p>Invalid question index. Please try again.</p>
      </Container>
    );
  }

  return (
    <Container className="my-5">
      {/* Back Button */}
      <Row className="mb-4">
        <Col>
          <Button variant="light" onClick={() => navigate("/")} className="back-button">
            <FaArrowCircleLeft className="me-2" />
            Back to Job Description
          </Button>
        </Col>
      </Row>

      {/* Question Indicator */}
      <Row className="text-center mb-4">
        <Col>
          <h1>Question {currentQuestionIndex + 1} of {questions.length}</h1>
        </Col>
      </Row>

      {/* Main Content */}
      <Row className="justify-content-center">
        <Col xs={12} md={10} lg={8}>
          <div className="qr-box">
            <p>{currentQuestion.question}</p>
            {showResponse && (
              <p><strong>Response:</strong> {currentQuestion.answer}</p>
            )}
            <Button variant="secondary" onClick={() => setShowResponse(!showResponse)}>
              {showResponse ? <><FaEyeSlash className="me-2" /> Hide Response</> : <><FaEye className="me-2" /> Show Response</>}
            </Button>
          </div>
        </Col>
      </Row>

      {/* Next and Previous Buttons */}
      <Row className="mt-4 justify-content-center">
        <Col xs={12} md={10} lg={8} className="d-flex justify-content-between align-items-center">
          <Button variant="secondary" onClick={handlePreviousQuestion} className="nav-button">
            <FaArrowLeft className="me-2" />
            Previous
          </Button>
          {currentQuestionIndex + 1 < questions.length ? (
            <Button variant="primary" onClick={handleNextQuestion} className="nav-button">
              Next
              <FaArrowRight className="ms-2" />
            </Button>
          ) : (
            <Button variant="primary" onClick={handleSummary} className="nav-button">
              Summary
            </Button>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default QuestionsPage;